import { makeStyles, Theme } from '@material-ui/core';
import fontStyles from '@/common/fontStyles';
import { smallMobileStartBreakpoint } from '@/materialUi/theme';
import colors from '@/common/colors';

interface Props {
  stackCTA: boolean;
  imageSize: 'sm' | 'md';
}

export const useMenuTileStyles = makeStyles<Theme, Props>((theme) => ({
  grid: (props) => ({
    padding: '16px',
    ...(props.stackCTA && {
      gridTemplateAreas: `
        "image info"
        "cta cta"
        "nutrition nutrition"
      `
    }),
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      gridTemplateAreas: `
        "image info"
        "cta cta"
        "nutrition nutrition"
      `
    },
    ...(props.stackCTA && {
      '&:first-child': {
        marginTop: 0
      }
    })
  }),
  cta: (props) => ({
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'row',
    marginLeft: 'auto',
    gap: '32px',
    ...(props.stackCTA && {
      gridColumn: '1 / 3',
      justifyContent: 'space-between',
      marginTop: '20px',
      marginLeft: '0',
      gap: '0'
    }),
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      gridColumn: '1 / 3',
      marginLeft: '0',
      marginTop: '20px',
      justifyContent: 'space-between'
    }
  }),
  ctaButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '16rem',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      flexGrow: 1
    }
  },
  ctaLinkWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      height: 'auto'
    }
  },
  ctaLink: {
    ...fontStyles.commonComponentSelectedState,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontWeight: 'bold',
    color: colors.blue,
    '&:focus': {
      padding: 0
    }
  },
  badges: {
    display: 'flex',
    gap: '5px',
    marginBottom: '4px'
  },
  imageContainer: (props) => ({
    width: '72px',
    height: '88px',
    margin: '0',
    marginRight: '16px',
    ...(props.imageSize === 'md' && {
      width: '104px',
      height: '121px'
    })
  }),
  imageClickable: {
    cursor: 'pointer'
  },
  infoIconButton: {
    padding: '0 0 0 8px'
  },
  nutrition: {
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    padding: '16px 16px 0 16px'
  },
  priceAndNameContainer: {
    padding: '0'
  },
  productNameAndCrustContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start'
  },
  title: {
    ...fontStyles.productTitle,
    fontSize: '14px'
  }
}));
